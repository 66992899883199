module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177450041-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"polina.business","short_name":"polina.b","start_url":"/","background_color":"#D9B8CA","theme_color":"#D9B8CA","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"bb1ea6285619731ec818d3f8428e1850"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
